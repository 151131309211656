import Vue from 'vue'
import sync from '~/assets/js/sync'
import utils from '~/assets/js/utils'
import events from '~/assets/js/events'

export default ({ app }) => {
	// to access inside store
	app.$swal = Vue.$swal
	app.getDataURL = utils.getDataURL
	app.getUpdatedAtDatetime = utils.getUpdatedAtDatetime
	app.applyMultiPricing = utils.applyMultiPricing
	app.toFixed = utils.toFixed
	app.filterItem = utils.filterItem
	
	Vue.mixin({
		methods: {
			...sync(app),
			...utils,
			...events
		}
	})
}
