var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',[_c('v-ons-toolbar',[_c('div',{staticClass:"left"},[_c('v-ons-back-button')],1),_vm._v(" "),_c('div',{staticClass:"center"},[_vm._v("\n\t\t\tTables\n\t\t")])]),_vm._v(" "),_c('section',{staticClass:"position-relative h-100",class:{
			'bg-white': _vm.tables.length === 0
		}},[_c('div',{staticClass:"position-fixed p-2",style:({
				left: 0,
				right: 0,
				backgroundColor: '#eee',
				zIndex: 1
			})},[_c('v-ons-search-input',{ref:"searchTerm",staticClass:"w-100",attrs:{"id":"customer-search","placeholder":"Search by table"},on:{"keyup":function($event){_vm.filterTables($event.currentTarget.value.trim())}}})],1),_vm._v(" "),(_vm.tables.length)?_c('section',{class:_vm.selectedTablesList?'mb-20':'',staticStyle:{"padding-top":"64px"}},_vm._l((_vm.tables),function(table,index){return _c('v-ons-list-item',{key:index,staticClass:"bg-white",attrs:{"tappable":""}},[_c('label',{staticClass:"left"},[_c('ons-checkbox',{attrs:{"input-id":("table-" + index),"value":JSON.stringify(table),"name":"selected-table","checked":_vm.selectedTablesList.findIndex(function (selectedTable) { return selectedTable.id === table.id; }) !== -1},on:{"change":function($event){return _vm.setSelectedTableList(table)}}})],1),_vm._v(" "),_c('label',{staticClass:"center",attrs:{"for":("table-" + index)}},[_vm._v("\n\t\t\t\t\t"+_vm._s(table.name)+" ("+_vm._s(table.floor.name)+") "+_vm._s(table.seat_count)+" Seats\n\t\t\t\t")])])}),1):_vm._e(),_vm._v(" "),_c('v-ons-fab',{staticStyle:{"background":"#ff8c00"},attrs:{"position":"bottom right","visible":_vm.selectedTablesList.length>0},on:{"click":function($event){return _vm.pageStack.pop();}}},[_c('v-ons-icon',{staticStyle:{"color":"#fff"},attrs:{"icon":"md-arrow-forward"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }