import Vue from 'vue'

import CashDrawer from '~/components/cash-drawer'
import ItemPopover from '~/components/sell/item-popover'
import TableOrdersAction from '~/components/table-orders-action'
import PriceVariableDialog from '~/components/price-variable-dialog'

const Modifiers = () => import('~/components/sell/modifiers')
const Discounts = () => import('~/components/discounts')
const Denominations = () => import('~/components/denominations')

Vue.component('modifiers', Modifiers)
Vue.component('discounts', Discounts)
Vue.component('cash-drawer', CashDrawer)
Vue.component('item-popover', ItemPopover)
Vue.component('table-orders-action', TableOrdersAction)
Vue.component('price-variable-dialog', PriceVariableDialog)
Vue.component('denominations', Denominations)
