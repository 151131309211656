<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				{{ title }}
			</div>
		</v-ons-toolbar>
		<v-ons-list v-if="item">
			<div class="edit-item-form p-3 display-block">
				<ValidationObserver ref="validator">
					<p class="m-0 title">
						Item Details
					</p>
					<hr class="under">
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title">
								Name <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="item-name"
								rules="required"
							>
								<v-ons-input
									v-model="itemName"
									name="item-name"
									:value="item.name"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								Category <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="category-name"
								rules="required"
							>
								<v-ons-input
									v-model="category.name"
									name="category-name"
									readonly
									:value="category.name"
									@click="selectCategory"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<p class="m-0 mt-3 title">
						Item Variation Details
					</p>
					<hr class="under">
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title">
								Name <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="variation-name"
								rules="required"
							>
								<v-ons-input
									v-model="variationName"
									name="variation-name"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								SKU <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="sku"
								rules="required"
							>
								<v-ons-input
									v-model="sku"
									name="sku"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item
						v-if="['restaurant', 'qsr'].includes(merchant.businessType)"
						modifier="nodivider"
					>
						<v-ons-col>
							<p class="m-0 input-title">
								Food Type <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="food type"
								rules="required"
							>
								<v-ons-input
									v-model="selectedFoodType"
									:value="selectedFoodType"
									readonlytype="number"
									readonly
									class="w-100"
									name="food-type"
									@click="foodTypeListVisible=true"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title">
								Unit of measurement <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="Unit of measurement"
								rules="required"
							>
								<v-ons-input
									v-model="selectedUnitOfMeasurement"
									:value="selectedUnitOfMeasurement"
									readonlytype="number"
									readonly

									name="food-type"
									@click="unitOfMeasurementListVisible=true"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								Stock <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="stock"
								rules="required"
							>
								<v-ons-input
									v-model="stock"

									type="number"
									name="stock"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title">
								Barcode
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="barcode"
								rules="alpha_num"
							>
								<v-ons-input
									v-model="barcode"
									name="barcode"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								Price Type <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="pricing-type"
								rules="required"
							>
								<v-ons-input
									v-model="pricingType"
									:value="pricingType"
									readonlytype="number"
									readonly
									name="pricing-type"
									@click="selectPriceType"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="mr-1">
							<p class="m-0 input-title">
								Buying Price Price <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="buying price"
								rules="required|decimal:2|min:.1"
							>
								<v-ons-input
									v-model="buyingPrice"
									type="number"
									name="Seprice"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								Selling Price <span class="text-danger">*</span>
							</p>
							<ValidationProvider
								v-slot="{ errors }"
								name="selling price"
								rules="required|decimal:2|min:.1"
							>
								<v-ons-input
									v-model="price"
									type="number"
									name="price"
								/>
								<small class="text-danger">
									{{ errors[0] }}
								</small>
							</ValidationProvider>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="ml-1">
							<p class="m-0 input-title">
								Taxes
							</p>
							<ons-input
								v-model="selectedTaxes"
								name="taxes"
								:value="selectedTaxText"
								readonly
								class="tax-list"
								@click="selectTaxes"
							/>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item
						v-if="!item.id"
						modifier="nodivider"
					>
						<v-ons-col class="ml-1">
							<label class="left">
								<ons-checkbox
									:value="trackInventory"
									:checked="trackInventory"
									@change="trackInventory=$event.target.checked"
								/>
							</label>
							<label
								class="center"
							>
								Track  Inventory
							</label>
						</v-ons-col>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<v-ons-col class="ml-1">
							<label class="left">
								<ons-checkbox
									:value="onlineTrackInventory"
									:checked="onlineTrackInventory"
									@change="onlineTrackInventory=$event.target.checked"
								/>
							</label>
							<label
								class="center"
							>
								Track Online Inventory
							</label>
						</v-ons-col>
					</v-ons-list-item>
					<div v-if="merchant.features.online_platform">
						<v-ons-list-item modifier="nodivider">
							<v-ons-col class="ml-1">
								<label class="left">
									<ons-checkbox
										:value="isAvailable"
										:checked="isAvailable"
										@change="isAvailable=$event.target.checked"
									/>
								</label>
								<label
									class="center"
								>
									Is Available
								</label>
							</v-ons-col>
						</v-ons-list-item>
					</div>

					<v-ons-list-item modifier="nodivider">
						<v-ons-button
							ref="submitBtn"
							modifier="large"
							class="position-fixed rounded-0"
							style="bottom: 0; left: 0; right: 0; z-index: 5;height: 40px;line-height: 40px;"
							@click="createItem($event)"
						>
							Submit
						</v-ons-button>
					</v-ons-list-item>
				</ValidationObserver>
			</div>
		</v-ons-list>

		<v-ons-action-sheet
			:visible.sync="priceTypeListVisible"
			cancelable
			title="Price Types"
		>
			<v-ons-list-item
				v-for="(priceType, index) in pricingTypes"
				:key="index"
				modifier="nodivider"
				class="bg-white"
				tappable
			>
				<label class="left">
					<ons-radio
						name="selected-price-type"
						:input-id="`price-type-${index}`"
						:value="JSON.stringify(priceType)"
						:checked="(pricingType).toLowerCase() === (priceType.text).toLowerCase()"
						@change="priceTypeSelecetd($event)"
					/>
				</label>
				<label
					:for="`price-type-${index}`"
					class="center"
				>
					{{ priceType.text }}
				</label>
			</v-ons-list-item>
		</v-ons-action-sheet>

		<v-ons-action-sheet
			:visible.sync="taxListVisible"
			cancelable
			title="Taxes"
		>
			<v-ons-list-item
				v-for="(taxItem, index) in taxes"
				:key="index"
				modifier="nodivider"
				class="bg-white"
				tappable
			>
				<label class="left">
					<ons-checkbox
						:input-id="`table-${index}`"
						:value="JSON.stringify(taxItem)"
						name="selected-taxes"
						:checked="selectedTaxes.findIndex(selectedTax => selectedTax.id === taxItem.id) !== -1"
						@change="setSelectedTaxList(taxItem)"
					/>
				</label>
				<label
					:for="`table-${index}`"
					class="center"
				>
					{{ taxItem.name }}
				</label>
			</v-ons-list-item>
		</v-ons-action-sheet>
		<v-ons-action-sheet
			:visible.sync="foodTypeListVisible"
			cancelable
			title="Food Type"
		>
			<v-ons-list-item
				v-for="(food, index) in foodTypes"
				:key="index"
				modifier="nodivider"
				class="bg-white"
				tappable
			>
				<label class="left">
					<ons-radio
						:input-id="`food-${index}`"
						:value="JSON.stringify(food)"
						name="selected-food-type"
						:checked="(selectedFoodType).toLowerCase() === (food.text).toLowerCase()"
						@change="foodTypeSelecetd($event)"
					/>
				</label>
				<label
					:for="`table-${index}`"
					class="center"
				>
					{{ food.text }}
				</label>
			</v-ons-list-item>
		</v-ons-action-sheet>
		<v-ons-action-sheet
			:visible.sync="unitOfMeasurementListVisible"
			cancelable
			title="Unit of measurement"
		>
			<v-ons-list-item
				v-for="(measurement, index) in unitOfMeasurements"
				:key="index"
				modifier="nodivider"
				class="bg-white"
				tappable
			>
				<label class="left">
					<ons-radio
						:input-id="`unitOfMeasurements-${index}`"
						:value="JSON.stringify(measurement)"
						name="selected-measurement"
						:checked="(unitOfMeasurement).toLowerCase() === (measurement.text).toLowerCase()"
						@change="measurementSelecetd($event)"
					/>
				</label>
				<label
					:for="`table-${index}`"
					class="center"
				>
					{{ measurement.text }}
				</label>
			</v-ons-list-item>
		</v-ons-action-sheet>
	</v-ons-page>
</template>

<script>
import Categories from '~/components/categories'
import { ValidationObserver, ValidationProvider } from "vee-validate"

export default {
	name: 'EditItem',
	components: {
		ValidationObserver,
		ValidationProvider
	},
	props: {
		title: {
			type: String,
			required: true
		},
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			pricingTypes: [
					{
						text: 'Fixed',
						value: 'fixed'
					},
					{
						text: 'Variable',
						value: 'variable'
					},
					{
						text: 'Multi',
						value: 'multi'
					}
				],
			priceTypeListVisible: false,
			selectedTaxText: '',
			taxListVisible: false,
			selectedCategoryId: 0,
			taxes: [],
			itemId: '',
			itemName: '',
			category: '',
			variationId: '',
			inventoryId: '',
			variationName: '',
			sku: '',
			barcode: '',
			pricingType: '',
			price: '',
			stock: '',
			selectedTaxes: [],
			foodType:'',
			selectedFoodType:'',
			foodTypes:[
				{
					text:'Veg',
					value:'veg'
				},
				{
					text:'Non-Veg',
					value:'nveg'
				},
				{
					text:'Egg',
					value:'egg'
				}
			],
			foodTypeListVisible:false,
			buyingPrice:'',
			unitOfMeasurements:[
				{
					text:'Count',
					value:'count'
				},
				{
					text:'Length',
					value:'length'
				},
				{
					text:'Capacity',
					value:'capacity'
				},
				{
					text:'Volume (l)',
					value:'volume'
				},
				{
					text:'Weight (kg)',
					value:'weight'
				},
				{
					text:'Time',
					value:'time'
				},
				{
					text:'Area',
					value:'area'
				},
				{
					text:'Other',
					value:'other'
				}
			],
			unitOfMeasurement:'',
			selectedUnitOfMeasurement:'',
			unitOfMeasurementListVisible:false,
			onlineTrackInventory:false,
			isAvailable:false,
			trackInventory:false,
		}
	},
	computed: {
		pageStack: {
			get() {
				return this.$store.state.pageStack
			},
			set(pageStack) {
				this.$store.commit('setPageStack', pageStack)
			}
		},
		deviceId() {
			return this.$store.state.deviceId
		},
		locationId() {
			return this.$store.state.locationId
		},
		merchant() {
			return this.$store.state.merchant
		},
	},
	beforeMount() {
		this.getData('getTaxes', 'taxes')
		if(this.item){
			const item = JSON.parse(JSON.stringify(this.item))
			if (item.id) {
				this.itemId = item.item_id
				this.itemName = item.item_name
				this.category = {
					id: item.category.id,
					name: item.category.name
				}

				this.selectedCategoryId = this.item.category.id
				this.variationId = item.id
				this.inventoryId = item.inventory_id
				this.variationName = item.name
				this.sku = item.sku
				this.barcode = item.barcode
				this.pricingType = item.pricing_type
				this.price = item.price
				this.stock = item.stock
				this.selectedTaxes = item.tax
				this.buyingPrice = item.custom_attributes.buyingPrice ? item.custom_attributes.buyingPrice : ''
				let filteredFoodType = this.foodTypes.filter(f=> item.food_type === f.value)
				if (filteredFoodType) {
					this.foodType = filteredFoodType[0].value
					this.selectedFoodType = filteredFoodType[0].text
				}
				this.trackInventory = item.custom_attributes.track_inventory?item.custom_attributes.track_inventory:false
				if (item.unit_measure_type) {
					let unitMeasureType = this.unitOfMeasurements.filter(u=> item.unit_measure_type===u.value)
					if (unitMeasureType) {
						this.unitOfMeasurement = unitMeasureType[0].value
						this.selectedUnitOfMeasurement = unitMeasureType[0].text
					}
				}
				this.onlineTrackInventory = item.custom_attributes.online_track_inventory ? item.custom_attributes.online_track_inventory : false
				this.isAvailable = item.custom_attributes.is_available ? item.custom_attributes.is_available : false
				this.getSelectedTaxesList()
			}
		}
	},
	methods: {
		getData(fnName, varName, searchTerm) {
			const data = this.$bridge[fnName](this.deviceId, JSON.stringify({
				search_term: searchTerm
			}))

			this[varName] = (typeof data === 'string' ? JSON.parse(data) : data).data
		},
		selectPriceType(){
			this.priceTypeListVisible = true
		},
		priceTypeSelecetd($event){
			this.priceTypeListVisible = false
			this.pricingType = JSON.parse($event.target.value).text
		},
		foodTypeSelecetd($event){
			this.foodTypeListVisible = false
			this.foodType = JSON.parse($event.target.value).value
			this.selectedFoodType = JSON.parse($event.target.value).text
		},
		measurementSelecetd($event){
			this.unitOfMeasurementListVisible = false
			this.unitOfMeasurement = JSON.parse($event.target.value).value
			this.selectedUnitOfMeasurement  = JSON.parse($event.target.value).text
		},
		selectTaxes(){
			this.taxListVisible = true
		},
		getSelectedTaxesList(){
			var taxResult = ''
			for (var i = 0; i < this.selectedTaxes.length; i++) {
				if(i > 0 && i < this.selectedTaxes.length){
					taxResult += ', ' + this.selectedTaxes[i].name
				} else {
					taxResult += this.selectedTaxes[i].name
				}
			}

			this.selectedTaxText = taxResult;
		},
		setSelectedTaxList(taxItem){
			for (var i = 0; i < this.selectedTaxes.length; i++) {
				if(this.selectedTaxes[i] && taxItem.id === this.selectedTaxes[i].id){
					this.selectedTaxes.splice(i, 1)
					this.getSelectedTaxesList()
					return true
				}
			}
			this.selectedTaxes.push(taxItem)
			this.getSelectedTaxesList()
		},
		selectCategory() {
			this.pageStack.push({
				extends: Categories,
				onsNavigatorProps: {
					categoryId: this.selectedCategoryId,
					emitCategory: (category) => {
						this.category = category
						this.selectedCategoryId = category.id
					}
				}
			})
		},
		async createItem($event) {
			if (await this.$refs.validator.validate() && this.$offline.state === 'up') {
				const el = $event.currentTarget

				el.disabled = true
				el.classList.add('running')

				this.$axios[this.itemId ? '$patch' : '$post']('/api/pos/v1/item', {
					id: this.itemId,
					name: this.itemName,
					category_id: this.category.id,
					location_id: this.locationId,
					is_active: true,
					variation: [{
						id: this.variationId,
						name: this.variationName,
						sku: this.sku,
						barcode: this.barcode,
						unit_measure_type:this.unitOfMeasurement,
						food_type:this.foodType,
						type: 'sale',
						is_active: true,
						inventory: [{
							id: this.inventoryId,
							location_id: this.locationId,
							pricing_type: this.pricingType,
							price: parseFloat(this.price),
							buying_price:parseFloat(this.buyingPrice),
							quantity_available: parseFloat(this.stock),
							tax: this.selectedTaxes.map(tax => tax.id),
							is_available:this.isAvailable ? 1 : 0,
							online_track_inventory:this.onlineTrackInventory ? 1 : 0,
							track_inventory:this.trackInventory,
							is_active: true
						}]
					}]
				}).then(response => {
					el.disabled = false
					el.classList.remove('running')
					if (response.status === 'success') {
						this.$bridge.customInsertOrUpdate(
							'Item', this.deviceId,this.locationId, this.$bridge.getName() === 'ANDROID' ?
								JSON.stringify([response.data.items]) : [response.data.items]
						)
						this.$ons.notification.toast(`Item ${this.itemId ? 'updated' : 'created'} successfully`, {
							timeout: 3000
						})
						this.$root.$emit('getItems')
						this.pageStack.pop()
					} else {
						this.$ons.notification.toast(`Something went wrong.`, {
							timeout: 3000
						})
					}
				}).catch(err => {
					if (err.response && err.response.status === 422) {
						const errors = {}
						for (let e in err.response.data.data.errors) {
							errors[e.split('.').pop()] = [err.response.data.data.errors[e].replace('variation.0.', '')]
							this.$refs.validator.setErrors(errors)
						}
					}
					el.disabled = false
					el.classList.remove('running')
				})
			} else if (this.$offline.state === 'down') {
				this.$ons.notification.toast(`Please connect to internet and try again.`, {
					timeout: 3000
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
	.title{
		font-size: 14px;
		color: #666;
	}
	hr.under{
		border-top: 1px solid #eee;
	}
	p.input-title{
		font-size: 14px;
		color: #afafaf;
	}
	.tax-list{
		width: 100%;
		font-size: 12px;
	}
</style>
