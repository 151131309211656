<template>
	<v-ons-page
		:id="`${type}-ITEMS`"
		:infinite-scroll="loadMoreItems"
	>
		<section class="content">
			<div
				v-if="searchBarVisibility"
				class="position-fixed pt-1"
				:style="{
					left: 0,
					right: 0,
					backgroundColor: '#ECEFF1',
					zIndex: 1
				}"
			>
				<v-ons-search-input

					id="item-search"
					ref="searchTerm"
					placeholder="Search by name / sku / barcode"
					class="w-100"
					@input="searchItems($event.currentTarget.value.trim())"
				/>
			</div>
			<section
				:class="{'search-bar-adjust':searchBarVisibility, 'default-main-layout':!searchBarVisibility }"
			>
				<div
					v-if="type==='ALL'"
					style=" position: absolute; left: 0; width: 30%; height: 100%; overflow: auto; background-color: #fff;"
				>
					<div
						v-for="(category, index) in categories"
						:key="index"
						tappable
						:class="{'selected': index === selectedCategory,'no-padding':true,'category-view':true}"
						@click.stop="setSelectedCategoryId(category.id,index)"
					>
						{{ category.name }}
					</div>
				</div>
				<div
					:class="{allItems : type==='ALL',
						otherTabs: type!=='ALL'}"
					style="position: absolute; height: 100%; overflow: auto;"
				>
					<div
						v-if="!loading && items.data.length"
						:class="{'items':true,'items-grid':gridView}"
					>
						<div
							v-for="(item, index) in items.data"
							:key="index"
							class="custom-row divider"
						>
							<div
								class="custom-col"
								@click.stop="addItem(item)"
							>
								<div class="item-name">
									{{ item.name }}
								</div>
								<div class="item-price">
									{{ item.price | currency({ symbol: merchant.customAttributes.currency_symbol }) }}
									<span
										v-if="item.pricing_type === 'variable'"
										class="text-success"
										style="font-size: smaller;"
									>Editable
									</span>
								</div>
								<div
									v-if="item.sku"
									class="item-sku"
								>
									SKU: {{ item.sku }}
								</div>
							</div>
							<div class="custom-col">
								<div
									v-if="!gridView"
									class="clearfix"
								>
									<v-ons-icon
										style="float: right; padding-right:4px;"
										icon="md-more-vert"
										@click.stop="showItemPopover(item, $event)"
									/>
								</div>
								<div
									v-if="!(cartItems[`${item.id}-${item.price}`]) || (cartItems[`${item.id}-${item.price}`].quantity===0)"
									class="add-button clearfix"
									@click.stop="addItemValidated(item)"
								>
									Add
								</div>
								<div
									v-else
									class="add-button clearfix custom-container"
								>
									<div
										class="container-left"
										@click.stop="changeQuantity(item, 'reduce', $event)"
									>
										-
									</div>
									<div class="container-middle">
										{{ cartItems[`${item.id}-${item.price}`].quantity.toFixed(getUnitDecimalPlaces(cartItems[`${item.id}-${item.price}`].unit_measure_type)) }}
									</div>
									<div
										class="container-right"
										@click.stop="changeQuantity(item, 'add', $event)"
									>
										+
									</div>
								</div>
								<div
									v-if="item && item.modifiers.length"
									class="text-center m-0 mt-1 clearfix"
									style="font-size:10px;align-self: center;color: green;"
								>
									Customizable
								</div>
							</div>
						</div>
					</div>
					<v-ons-row
						v-if="loading"
						class="h-100 text-muted"
					>
						<v-ons-col
							class="text-center"
							vertical-align="center"
						>
							<v-ons-progress-circular indeterminate />
						</v-ons-col>
					</v-ons-row>
					<v-ons-row
						v-if="!loading && items.data.length===0"
						class="h-100 text-muted"
					>
						<v-ons-col
							class="text-center"
							vertical-align="center"
						>
							<v-ons-icon
								icon="fa-exclamation-triangle"
								size="3x"
							/>
							<h3>No Items Found</h3>
						</v-ons-col>
					</v-ons-row>
				</div>
			</section>
		</section>
		<price-variable-dialog />
	</v-ons-page>
</template>

<script>
	import Variations from './variations'

	export default {
		name: 'Items',
		components: {
		},
		props: {
			type: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				items: {
					data: [],
					total: 0,
					offset: 0
				},
				searchTimer: null,
				priceCategories: [{
					id: null,
					name: 'Default'
				}],
				weight: 0,
				keystrokes: '',
				categories: [{ id: null, name: 'All'}],
				selectedCategoryId: null,
				selectedCategory:0,
				loading: true
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.merchant
			},
			selectedPriceCategory() {
				return this.$store.state.selectedPriceCategory
			},
			showVariablePriceModel: {
				get() {
					return this.$store.state.showVariablePriceModel
				},
				set(data) {
					this.$store.commit('setShowVariablePriceModel', data)
				}
			},
			selectedItem: {
				get() {
					return this.$store.state.selectedItem
				},
				set(item) {
					this.$store.commit('setState', {
						key: 'selectedItem',
						value: item
					})
				}
			},
			selectedVariation: {
				get() {
					return this.$store.state.selectedVariation
				},
				set(variation) {
					this.$store.commit('setSelectedVariation', variation)
				}
			},
			cartItems() {
				const cartItems = {}

				this.$store.state.cart.items.forEach(i => cartItems[`${i.id}-${i.price}`] = i)

				return cartItems
			},
			favorites() {
				return this.$store.state.favorites
			},
			settings() {
				return this.$store.state.settings
			},
			isWeighingScaleEnabled() {
				return this.$store.state.isWeighingScaleEnabled
			},
			searchBarVisibility(){
				return this.$store.state.searchBarVisibility
			},
			gridView(){
				return this.$store.state.toggleViews;
			}
		},
		watch: {
			selectedPriceCategory(priceCategory) {
				this.setPriceCategoryToItem()
			},
			selectedVariation(variation) {
				if (variation) {
					const itemIndex = this.items.data.findIndex(i => i.id === this.selectedItem.id)
					if (itemIndex !== -1) {
						this.items.data[itemIndex] = variation
						this.items = { ...this.items }
					}
				} else {
					this.getItems({})
				}
			}
		},
		beforeMount() {
			this.initEventBus()
		},
		created(){
			this.getCategories(null)
			this.getItems({})
		},
		mounted() {
			setTimeout(() => {
				const activeTab = document.querySelector('.tabbar__item.active').getAttribute('label')

				document.querySelector('.tabbar__border').style.transform = `translate3d(${
					activeTab === 'All Items' ? 0 : (activeTab === 'Favorites' ? 100 : 200)
				}%, 0px, 0px)`
			}, 50)
			document.addEventListener('keydown', this.barcodeScannerEvent)
			window.emitBarcodeScanner = this.barcodeScanner
			window.emitWeight = this.emitWeighingScale
		},
		destroyed() {
			document.removeEventListener('keydown', this.barcodeScanner)
		},
		methods: {
			getItems({ variationId, searchTerm, categoryId, offset }) {
				let items = this.$bridge.getItems(this.deviceId, this.locationId, JSON.stringify({
					variation_id: variationId,
					id: this.type === 'FAVORITES' ? (
						this.favorites.length ? this.favorites : 0
					) : null,
					search_term: searchTerm ? searchTerm : null,
					category_id: categoryId || this.selectedCategoryId,
					topSold: this.type === 'TOP',
					offset: offset,
					list_variations:true
				}))

				items = typeof items === 'string' ? JSON.parse(items) : items

				if (offset)
					items.data = this.items.data.concat(items.data)

				this.items = items
				this.loading = false
				if(this.selectedPriceCategory.id) this.setPriceCategoryToItem()
			},
			searchItems(searchTerm) {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.loading = true
					this.getItems({
						searchTerm: searchTerm,
						categoryId: typeof this.category === 'number' ?
							this.category : (this.category ? this.category.id : null)
					})
				}, 500)
			},
			loadMoreItems(done) {
				if (this.items.data.length < this.items.total) {
					this.getItems({
						searchTerm: this.$refs.searchTerm? this.$refs.searchTerm.value : null,
						categoryId: typeof this.category === 'number' ?
							this.category : (this.category ? this.category.id : null),
						offset: this.items.offset + 20
					})
				}

				setTimeout(done, 1000)
			},
			emitWeighingScale(weight){
				if(this.selectedItem.quantity > 0){
					this.selectedItem.quantity = this.cartItems[`${this.selectedItem.id}-${this.selectedItem.price}`].quantity + parseFloat(weight)
				} else {
					this.selectedItem.quantity = parseFloat(weight)
				}
				this.$store.dispatch('modifyCart', {
					item: {
						id: this.selectedItem.item_id,
						name: this.selectedItem.name,
						category_id: this.selectedItem.category.id
					},
					variation: this.selectedItem
				})
				this.$bridge.disconnectWeighingScale()
			},
			addItem(item) {
				let variation = item
				this.selectedItem = item
				this.selectedVariation = variation
				this.showVariablePriceModel = true
			},
			addItemValidated(item){
				if (item.batch.length) {
					this.addItem(item)
				}else {
						let variation = item
						if (variation.unit_measure_type === 'weight'
								&& this.isWeighingScaleEnabled && this.$bridge.isWeighingScaleConnected()) {
							this.selectedItem = item
							this.$bridge.getWeight()
							return
						} else {
							if(!variation.quantity)
								variation.quantity = 1
						}
						return this.$store.dispatch('modifyCart', {
							item: {
								id: item.item_id,
								name: item.item_name,
								category_id: item.category.id
							},
							variation: variation
						})
					}
			},
			async changeQuantity(item, type, $event) {
				if (type === 'add') {
					if (item.unit_measure_type === 'weight' && this.isWeighingScaleEnabled
							&& this.$bridge.isWeighingScaleConnected()) {
						this.selectedItem = item
						this.$bridge.getWeight()
						return
					} else {
						item.quantity = parseFloat(this.cartItems[`${item.id}-${item.price}`].quantity + 1)
					}
				} else if (type === 'reduce') {
					item.quantity = parseFloat(this.cartItems[`${item.id}-${item.price}`].quantity - 1)
				}
				item.quantity = parseFloat(item.quantity.toFixed(
					this.getUnitDecimalPlaces(item.unit_measure_type)
				))

				return this.$store.dispatch('modifyCart', {
					item: {
						id: item.item_id,
						name: item.item_name,
						category_id: item.category.id
					},
					variation: {
								...item,
								batch_id: item.batch_id ? item.batch_id : null
							}

				})
			},
			changeQuantityModel(item, type, $event){
				let variation = item
				this.selectedItem = item
				this.selectedVariation = variation
				this.showVariablePriceModel = true
			},
			initEventBus() {
				this.$root.$on('getItems', () => {
					this.getItems({
						searchTerm: document.querySelector('#item-search input') ? document.querySelector('#item-search input').value : null,
						categoryId: this.selectedCategoryId
					})
				})

				this.$root.$on('reRenderItems', $event => {
					if (this.type === $event)
						this.getItems({})
				})
			},
			showVariations(item) {
				this.selectedItem = item
				this.pageStack.push(Variations)
			},
			showItemPopover(item, $event) {
				this.selectedItem = item
				this.selectedVariation = item
				this.$store.commit('setItemPopover', {
					show: true,
					target: $event
				})
			},
			barcodeScanner(barcode){
				let items = this.$bridge.getItems(this.deviceId, this.locationId, JSON.stringify({
					search_term: barcode,
					search_source: 'keyboard'
				}))

				items = (typeof items === 'string' ? JSON.parse(items) : items).data

				if (items.length === 1) {
					if(this.cartItems[`${items[0].id}-${items[0].price}`]){
						this.changeQuantity(items[0], 'add')
					} else {
						this.addItemValidated(items[0])
					}
				} else {
					let quantity = 0
					let price = 0
					let quantityDecimalIndex = -1

					const quantityLength = parseInt(this.settings.barcode.quantity_length || 0)
					const priceLength = parseInt(this.settings.barcode.price_length || 0)
					const decimalIndex = barcode.indexOf('.')

					barcode = barcode.replace(/\./g, '')

					if (decimalIndex !== -1)
						quantityDecimalIndex = barcode.length - decimalIndex

					if (quantityLength) {
						quantity = barcode.slice(-quantityLength)
						barcode = barcode.slice(0, -quantityLength)

						if (quantityDecimalIndex !== -1) {
							quantity = `${quantity.slice(0, -quantityDecimalIndex)}.${
								quantity.slice(-quantityDecimalIndex)
							}`
						}
					}

					if (priceLength) {
						price = barcode.slice(-priceLength)
						barcode = barcode.slice(0, -priceLength)
					}

					if (barcode) {
						let items = this.$bridge.getItems(this.deviceId, this.locationId, JSON.stringify({
							search_term: barcode
						}))

						items = (typeof items === 'string' ? JSON.parse(items) : items).data

						if (items.length === 1) {
							if (quantity) {
								if (items[0].unit_measure_type === 'weight' &&
								!quantity.includes('.'))
									quantity = quantity / 1000

								items[0].quantity = +quantity
							}

							if (price)
								items[0].price = price / 100

							if(this.cartItems[`${items[0].id}-${items[0].price}`]){
								items[0].quantity = parseFloat(parseFloat(items[0].quantity + this.cartItems[`${items[0].id}-${items[0].price}`].quantity).toFixed(this.getUnitDecimalPlaces(items[0].unit_measure_type)))
							}

							this.addItemValidated(items[0])
						}
					}
				}
				this.keystrokes = ''
			},
			barcodeScannerEvent(e){
				if (![
					'Meta', 'Enter', 'Shift', 'Delete', 'F1', 'F2', 'F3',
					'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'
				].includes(e.key))
					this.keystrokes += e.key
				if (e.key === 'Enter' && this.keystrokes.length) {
					this.barcodeScanner(this.keystrokes)
				}
			},
			setPriceCategoryToItem(){
				const page = document.getElementById(`${this.type}-ITEMS`)

				if (page.hasAttribute('shown')) {
					const data = this.items.data.map(item => {
						if (item.pricing_type === 'multi' && this.selectedPriceCategory.id)
							item = this.applyMultiPricing(item, this.selectedPriceCategory.id)
						else
							item.price = item.original_price

						return item
					})

					this.items = {
						...this.items,
						data: data
					}
				}
			},
			getCategories(searchTerm) {
				let categories = this.$bridge.getCategories(this.deviceId, JSON.stringify({
					search_term: searchTerm
				}))

				categories = (typeof categories === 'string' ? JSON.parse(categories) : categories).data
				this.categories = this.categories.concat(categories)
			},
			searchCategories(searchTerm) {
				clearTimeout(this.searchTimer)
				this.searchTimer = setTimeout(() => {
					this.getCategories(searchTerm)
				}, 100)
			},
			setSelectedCategoryId(id,index) {
				this.loading = true
				this.selectedCategory = index
				this.selectedCategoryId =  id
				this.getItems({ categoryId: this.selectedCategoryId })
			}
		}
	}
</script>

<style lang="scss">
	.search-bar-adjust{
		height: 98%;
		margin-top: 56px;
	}
	.default-main-layout{
		margin-top: 4px;
	}

	.add-item-btn {
		.button--material {
			border-radius: 0;
		}
	}
	.item-card{
		background-color: #fff;
		padding: 8px;
	}
	.selected {
  	background-color: #ff8c00;
		color: white;
		font-style: bold;
	}
	.category-view{
		display: -webkit-box;
		padding: 8px;
    font-size: 12px;
	}
	.item-name{
		margin: 0px;
		font-size: medium;
	}
	.divider{
		border-bottom: .5px solid #BDBDBD;
	}
	.item-price{
		font-size: smaller;
	}
	.allItems{
		width: 70%;
		left: 30%;
	}
	.otherTabs{
		width: 100%;
		left: 0;
	}
	.add-button{
		color: green;
		line-height: 30px;
		margin-top: 8px;
		margin-bottom: 4px;
		margin-right: 2px;
		text-align: center;
    border: 1px solid green;
		box-sizing: border-box;
	}
	.item-sku{
		font-size: small;
	}
	.custom-row{
		background: #fff;
		width: 100%;
		padding: 4px;
		margin-bottom: 4px;
		box-sizing: border-box;
	}
	.items-grid{
		justify-content: space-between;
	}

	.items-grid .custom-row{
		width: 49%;
	}
	.items{
		padding-left: 4px;
    padding-right: 4px;
		display: flex;
		flex-wrap: wrap;
	}
	.dropdown-content {
	  display: none;
	  position: absolute;
	  background-color: #f9f9f9;
	  min-width: 160px;
	  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
	  z-index: 1;
	}
	.items-grid .custom-col{
		width: 100%;
		float: none;
	}
	.custom-col{
		width: 50%;
		float: left;
	}
	.custom-container {height: 100%; width:100%; font-size: 0;}
	.container-left, .container-middle, .container-right {display: inline-block; vertical-align: top; font-size: 12px;}
	.container-left {width: 33%; font-size: x-large; float: left;}
	.container-middle {width: 33%; font-size: medium; float: left;}
	.container-right {width: 33%; font-size: x-large; float: left;}
</style>
