<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Custom Sale
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<v-ons-list v-if="item">
			<div class="p-1">
				<ValidationObserver ref="validator">
					<v-ons-list-item modifier="nodivider">
						<v-ons-input
							v-model.trim="name"
							:placeholder="item.name"
							class="w-100"
							type="text"
							name="name"
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="quantity"
							rules="required"
							class="w-100"
						>
							<v-ons-input
								v-model="quantity"
								type="number"
								min="1"
								class="w-100"
								float
								placeholder="Quantity"
								name="quantity"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ons-input
							v-model="selectedTaxText"
							name="taxes"
							:value="selectedTaxText"
							readonly
							float
							placeholder="Tax"
							class="w-100"
							@click="()=> {
								taxListVisible = true
							}"
						/>
					</v-ons-list-item>
					<v-ons-list-item modifier="nodivider">
						<ValidationProvider
							v-slot="{ errors }"
							name="price"
							class="w-100"
							rules="required|min_value:.1|decimal:2"
						>
							<v-ons-input
								v-model="price"
								name="price"
								class="w-100"
								type="number"
								placeholder="Price"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</ValidationProvider>
					</v-ons-list-item>
				</validationobserver>
			</div>
			<v-ons-row
				style="bottom: 0; z-index: 5;height: 40px;line-height: 40px;"
				class="position-fixed"
			>
				<v-ons-col
					style="left: 0;right: 0;"
				>
					<v-ons-button
						class="rounded-0 bg-danger"
						modifier="large"
						@click="reset"
					>
						Reset
					</v-ons-button>
				</v-ons-col>
				<v-ons-col
					style="left: 0;right: 0;"
				>
					<v-ons-button
						class="rounded-0"
						modifier="large"
						@click="addCustomItem"
					>
						Add
					</v-ons-button>
				</v-ons-col>
			</v-ons-row>
			<v-ons-action-sheet
				:visible.sync="taxListVisible"
				cancelable
				title="Taxes"
			>
				<v-ons-list-item
					v-for="(taxItem, index) in taxes"
					:key="index"
					class="bg-white"
					tappable
				>
					<label class="left">
						<ons-checkbox
							:input-id="`table-${index}`"
							:value="JSON.stringify(taxItem)"
							name="selected-taxes"
							:checked="selectedTaxes.findIndex(selectedTax => selectedTax.id === taxItem.id) !== -1"
							@change="setSelectedTaxList(taxItem)"
						/>
					</label>
					<label
						:for="`table-${index}`"
						class="center"
					>
						{{ taxItem.name }}
					</label>
				</v-ons-list-item>
			</v-ons-action-sheet>
		</v-ons-list>
		<v-ons-row
			v-else
			class="h-100 text-muted"
		>
			<v-ons-col
				class="text-center"
				vertical-align="center"
			>
				<v-ons-icon
					icon="fa-exclamation-triangle"
					size="3x"
				/>
				<h3>No Items Found</h3>
			</v-ons-col>
		</v-ons-row>
	</v-ons-page>
</template>

<script>
	import { ValidationObserver, ValidationProvider } from "vee-validate"

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data() {
			return {
				item: null,
				name: '',
				price: '',
				quantity: 1,
				taxes: [],
				selectedTaxes: [],
				selectedTaxText: '',
				taxListVisible: false
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.merchant
			},
			settings() {
				return this.$store.state.settings
			},
			showCustomSale: {
				get() {
					return this.$store.state.showCustomSale
				},
				set(data) {
					this.$store.commit('setShowCustomSale', data)
				}
			},
			customItemsCount() {
				return this.$store.state.cart.items.filter(i => i.itemization_type === 'custom_amount').length
			}
		},
		beforeMount() {
			const item = this.$bridge.getItems(this.deviceId, this.locationId, JSON.stringify({
				variation_id: parseInt(this.settings.sale.custom_sale)
			}))
			const taxes = this.$bridge.getTaxes(this.deviceId, '')

			this.item = (typeof item === 'string' ? JSON.parse(item) : item).data[0]
			this.taxes = (typeof taxes === 'string' ? JSON.parse(taxes) : taxes).data
		},
		methods: {
			reset() {
				this.name = ''
				this.price = ''
				this.selectedTaxes = []
				this.selectedTaxText = ''
				this.quantity = 1
				this.$refs.validator.reset()
			},
      async addCustomItem() {
				if (await this.$refs.validator.validate()) {
					this.item.name = this.name || this.item.name
					this.item.price = this.price
					this.item.quantity = parseFloat(this.quantity)
					this.item.tax = this.selectedTaxes
					this.item.batch_id = `BATCH-${this.customItemsCount + 1}`
					this.item.itemization_type = 'custom_amount'
					this.$store.dispatch('modifyCart', {
						item: {
							id: this.item.item_id,
							name: this.name || this.item.item_name,
							category_id: this.item.category.id
						},
						variation: this.item
					})
					this.reset()
					this.pageStack.pop()
				}
			},
			setSelectedTaxList(taxItem){
				for (var i = 0; i < this.selectedTaxes.length; i++) {
					if(this.selectedTaxes[i] && taxItem.id === this.selectedTaxes[i].id){
						this.selectedTaxes.splice(i, 1)
						this.getSelectedTaxesList()
						return true
					}
				}
				this.selectedTaxes.push(taxItem)
				this.getSelectedTaxesList()
			},
			getSelectedTaxesList(){
				var taxResult = ''
				for (var i = 0; i < this.selectedTaxes.length; i++) {
					if(i > 0 && i < this.selectedTaxes.length){
						taxResult += ', ' + this.selectedTaxes[i].name
					} else {
						taxResult += this.selectedTaxes[i].name
					}
				}

				this.selectedTaxText = taxResult;
			}
		}
	}
</script>
