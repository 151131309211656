<template>
	<v-ons-page>
		<v-ons-toolbar>
			<div class="left">
				<v-ons-back-button />
			</div>
			<div class="center">
				Checkout
			</div>
			<div class="right">
				<v-ons-toolbar-button
					v-if="merchant.businessType === 'restaurant' && source === 'sell'"
					icon="fa-utensils"
					@click="assignTable"
				>
					<span
						v-if="merchant.businessType === 'restaurant' && selectedTables && selectedTables.length > 0"
						class="notification-dot notification notification--material position-absolute"
					>
						{{ ' ' }}
					</span>
				</v-ons-toolbar-button>
				<v-ons-toolbar-button
					icon="md-account-add"
					@click="addCustomer"
				>
					<span
						v-if="cart.customer.id"
						class="notification-dot notification notification--material position-absolute"
					>
						{{ ' ' }}
					</span>
				</v-ons-toolbar-button>
			</div>
		</v-ons-toolbar>
		<div class="background bg-white" />
		<div class="content">
			<v-ons-list class="mb-5">
				<v-ons-list-header v-if="cart.customer.id">
					<v-ons-row>
						<v-ons-col vertical-align="center">
							Selected Customer
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0 m-0"
								@click="resetCustomer"
							/>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-header>
				<v-ons-list-item v-if="cart.customer.id">
					<v-ons-row class="center pt-0">
						<v-ons-col>
							<p class="text-capitalize m-0">
								{{ cart.customer.first_name + ' ' + (cart.customer.last_name || '') }}
							</p>
							<small>{{ cart.customer.phone | mask('mobile') }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Limit
							</p>
							<small>{{ cart.customer.credit_limit | currency({
								symbol: merchant.customAttributes.currency_symbol
							}) }}</small>
						</v-ons-col>
						<v-ons-col class="text-center">
							<p class="m-0">
								Outstanding
							</p>
							<small>{{ (cart.customer.credit && !cart.customer.debit ? cart.customer.credit : (cart.customer.credit - cart.customer.debit)) | currency({
								symbol: merchant.customAttributes.currency_symbol
							}) }}</small>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-item>
				<v-ons-list-item v-if="cart.customer.customer_id && $offline.state === 'up'">
					<div
						class="right px-2"
					>
						<v-ons-button
							class="float-right"
							modifier="small"
							@click="getLoyaltyPoints"
						>
							Check Loyalty Points
						</v-ons-button>
					</div>
				</v-ons-list-item>
				<v-ons-list-header v-if="selectedTables && selectedTables.length">
					<v-ons-row>
						<v-ons-col vertical-align="center">
							Selected Table
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0"
								@click="resetSelectedTable"
							/>
						</v-ons-col>
					</v-ons-row>
				</v-ons-list-header>
				<v-ons-list v-if="selectedTables && selectedTables.length">
					<v-ons-list-item
						v-for="(tableItem, index) in selectedTables"
						:key="index"
						class="center"
					>
						<v-ons-col
							width="70%"
							class="m-0"
						>
							{{ tableItem.name }} ({{ tableItem.floor.name }}) {{ tableItem.seat_count }} Seats
						</v-ons-col>
						<v-ons-col vertical-align="center">
							<v-ons-toolbar-button
								icon="md-close-circle"
								class="float-right p-0"
								style="margin-right: 13px;"
								@click="resetSelectedTableItem(index)"
							/>
						</v-ons-col>
					</v-ons-list-item>
				</v-ons-list>
				<div
					v-if="selectedTables == null || selectedTables.length === 0"
				>
					<v-ons-list-header>
						Payment Methods
					</v-ons-list-header>
					<v-ons-list-item
						v-for="(paymentMethod, index) in paymentMethods"
						:key="index"
						:expanded="selectedPaymentMethod && selectedPaymentMethod.slug === paymentMethod.slug"
						expandable
						@click="setPaymentMethod(paymentMethod, $event)"
					>
						<div class="center">
							{{ paymentMethod.name }}
						</div>
						<div class="expandable-content">
							<ValidationObserver ref="validator">
								<ValidationProvider
									v-if="selectedPaymentMethod && selectedPaymentMethod.slug === 'cash'"
									ref="cashValidator"
									v-slot="{ errors }"
									name="cash-amount"
									:rules="{
										required: true,
										min_value: totalPrice.toFixed(2)
									}"
								>
									<v-ons-input
										v-model="cashAmount"
										type="number"
										step=".01"
										name="cash-amount"
										size="lg"
										class="w-100"
										placeholder="Tender amount"
										float
									/>
									<small class="text-danger">{{ errors[0] }}</small>
									<a
										v-if="($store.state.settings.denominations.length>1)"
										style="margin-top: 5px; float: right;"
										href="#"
										@click="() => {
											addDenomination = true
										}"
									>
										Add denomination
									</a>
								</ValidationProvider>
								<div v-else-if="selectedPaymentMethod && selectedPaymentMethod.slug === 'other'">
									<h4 class="text-center">
										Tender Amount: {{ totalPrice | currency({
											symbol: merchant.customAttributes.currency_symbol
										}) }}
									</h4>
									<div class="card-deck mb-6">
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'cheque'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Cheque
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'gift_card'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Gift Card
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'mobile_wallet'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Mobile Wallet
												</Small>
											</div>
										</div>
										<div
											class="card bg-white cursor-pointer"
											style="display: inline-block; padding: 8px"
											@click.prevent="activateCard($event)"
											@click.exact="otherPaymentType = 'mobile'"
										>
											<div class="card-body align-items-center d-flex justify-content-center">
												<Small class="text-center m-0">
													Mobile Payment
												</Small>
											</div>
										</div>
									</div>
								</div>
								<ValidationProvider
									v-else-if="selectedPaymentMethod && selectedPaymentMethod.slug === 'card' && !isEzSwype"
									ref="cardValidator"
									v-slot="{ errors }"
									name="card-number"
									:rules="{
										required: true,
										length : 4,
										numeric: true
									}"
								>
									<v-ons-input
										v-model="cardNumber"
										maxlength="4"
										type="number"
										step="1"
										name="card-number"
										class="w-100"
										placeholder="Last 4 digits on card"
										float
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</ValidationProvider>
								<div
									v-else-if="selectedPaymentMethod && selectedPaymentMethod.slug === 'split'"
									class="text-center"
								>
									<h4 class="text-center">
										{{ totalPrice | currency({
											symbol: merchant.customAttributes.currency_symbol
										}) }}
									</h4>
									<v-ons-button
										style="margin: 3px"
										@click="splitActionSheetVisible=true"
									>
										Select payment method
									</v-ons-button>
									<div
										v-if="orderPayments.length"
										class="mt-4"
									>
										<div
											v-for="(pm, payment_index) in orderPayments"
											:key="payment_index"
										>
											<v-ons-input
												:placeholder="pm.name"
												type="number"
												float
												:value="pm.amount"
												style="height: 50px"
												class="w-100"
												@input="calculateSplitAmount(payment_index,$event.currentTarget.value.trim())"
											/>
										</div>
										<ValidationProvider
											ref="splitValidator"
											v-slot="{ errors }"
											class="mt-2"
											style="height: 70px"
											name="split-total"
											:rules="{
												required: true,
												min_value: totalPrice.toFixed(2)
											}"
										>
											<v-ons-input
												v-model="splitTotal"
												step="1"
												class="w-100"
												style="font-size:18px"
												placeholder="Split Total"
												data-vv-as="split total"
												type="number"
												float
												readonly
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</ValidationProvider>
									</div>
								</div>
								<div
									v-else
									class="text-center"
								>
									<h4>
										Tender Amount: {{ totalPrice | currency({
											symbol: merchant.customAttributes.currency_symbol
										}) }}
									</h4>
								</div>
							</ValidationObserver>
						</div>
					</v-ons-list-item>
					<v-ons-list-item>
						<v-ons-input
							v-model="paymentNote"
							size="lg"
							class="w-100 mt-2"
							style="height: 50px"
							placeholder="Optional Notes"
							float
						/>
					</v-ons-list-item>
				</div>
			</v-ons-list>
		</div>
		<section
			class="position-fixed bg-white border-top p-2"
			style="left: 0; bottom: 52px; right: 0"
		>
			<v-ons-row>
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					Subtotal
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span>:</span>
					<span class="float-right">
						{{ cart.price.subTotal | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</span>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row>
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					Tax
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span>:</span>
					<span class="float-right">
						{{ cart.price.tax | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</span>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row v-if="cart.price.discount > 0">
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					Discount
					<v-ons-toolbar-button
						icon="md-close-circle"
						class="p-0 m-0"
						style="font-size: 15px"
						@click="()=>{
							selectedLoyaltyDiscount = null
							selectedDiscount = null}"
					/>
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span>:</span>
					<span class="float-right">
						{{ -cart.price.discount | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</span>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row v-if="cart.price.roundOff">
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					Round Off
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span>:</span>
					<span class="float-right">
						{{ cart.price.roundOff | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</span>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row v-if="cart.total_charge && cart.total_charge>0">
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					Charges
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span>:</span>
					<span class="float-right">
						{{ cart.total_charge | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</span>
				</v-ons-col>
			</v-ons-row>
			<v-ons-row vertical-align="center">
				<v-ons-col
					width="60%"
					vertical-align="center"
				>
					<h4 class="m-0">
						Total
					</h4>
				</v-ons-col>
				<v-ons-col vertical-align="center">
					<span class="m-0">
						:
					</span>
					<h4 class="float-right m-0 ">
						{{ cart.price.total | currency({
							symbol: merchant.customAttributes.currency_symbol
						}) }}
					</h4>
				</v-ons-col>
			</v-ons-row>
		</section>
		<v-ons-bottom-toolbar style="height: 52px;">
			<div
				class="h-100 px-2"
				style="display: flex;"
			>
				<v-ons-col
					v-if="showConfirmWOPrint || (selectedTables != null && selectedTables.length)"
					vertical-align="center"
					:class="(selectedTables == null || selectedTables.length < 1)? 'mr-1': ''"
				>
					<v-ons-button
						modifier="large"
						@click="preProcessOrder(false)"
					>
						{{ (selectedTables != null && selectedTables.length) ? 'Send To KOT' : 'Confirm W/O Print' }}
					</v-ons-button>
				</v-ons-col>
				<v-ons-col
					v-if="selectedTables == null || selectedTables.length === 0"
					vertical-align="center"
				>
					<v-ons-button
						modifier="large"
						@click="preProcessOrder(true)"
					>
						{{ 'Confirm' }}
					</v-ons-button>
				</v-ons-col>
			</div>
		</v-ons-bottom-toolbar>
		<table-orders-action
			v-if="showTableOrdersModal && tableOrders.length > 0"
			:orders="tableOrders"
			@update:showTableOrdersModal="showTableOrdersModal = $event"
			@update:processOrder="processTableOrder($event)"
		/>
		<v-ons-action-sheet
			:visible.sync="splitActionSheetVisible"
			cancelable
			title="Split Payment"
		>
			<v-ons-list>
				<v-ons-list-header>
					Choose payment method
				</v-ons-list-header>
				<v-ons-list-item
					v-for="(payment,index) in splitPayments"
					:key="payment.id"
					tappable
				>
					<label class="left">
						<v-ons-checkbox
							:input-id="'checkbox-' + index"
							@change="selectedSplitPayment($event.target.checked,payment)"
						/>
					</label>
					<label
						class="center"
						:for="'checkbox-' + index"
					>
						{{ payment.name }}
					</label>
				</v-ons-list-item>
			</v-ons-list>
		</v-ons-action-sheet>
		<v-ons-action-sheet
			title="Denominations"
			modifier="denominations-action-sheet-container"
			cancelable
			:visible.sync="addDenomination"
			@posthide="() => {
				cashAmount = denominationAmount !== null ? denominationAmount : cashAmount
				addDenomination = false
			}"
		>
			<div>
				<denominations
					@update:amount="denominationAmount = $event"
				/>
			</div>
		</v-ons-action-sheet>

		<v-ons-action-sheet
			:visible.sync="redeemActionSheetVisible"
		>
			<div
				class="background bg-white pt-2"
			>
				<h3 style="text-align: center">
					Loyalty Points
				</h3>
				<div
					v-if="!loyalty"
					style="height:100px;"
					class="text-center"
				>
					<v-ons-progress-circular indeterminate />
				</div>
				<v-ons-list
					v-else-if="loyalty && loyalty.discounts.length"
					style="height:250px; padding-left: 8px;padding-right: 8px;"
				>
					<v-ons-list-item
						v-for="discount in loyalty.discounts"
						:key="discount.id"
						tappable
					>
						<label class="left">
							<v-ons-radio
								:input-id="discount.id"
								@click="selectedLoyaltyDiscount = discount"
							/>
						</label>
						<label
							class="center"
						>
							<p>
								<span> Get </span>
								<span v-if="discount.getDiscountType === 'percentage'">
									{{ discount.getDiscountValue.toFixed(2) }}% Order Subtotal Discount
								</span>
								<span v-else-if="discount.getDiscountType === 'price'">
									{{ discount.getDiscountValue.toFixed(2) | currency({
										symbol: merchant.customAttributes.currency_symbol
									}) }} Order Subtotal Discount
								</span>
							</p>

						</label>
					</v-ons-list-item>
				</v-ons-list>
				<div
					v-else
					style="height:100px;"
					class="text-center"
				>
					<h5 class="mb-0">
						No Loyalty Discounts Found
					</h5>
				</div>


				<v-ons-bottom-toolbar style="height: 52px;">
					<v-ons-row style="height: 52px;">
						<v-ons-col
							class="m-2"
						>
							<div
								v-if="loyalty"
								style="flex-direction:row"
							>
								<div>Earned: {{ loyalty.totalPoints }}</div>
								<div>Redeemable: {{ loyalty.redeemablePoints }}</div>
							</div>
						</v-ons-col>
						<v-ons-col
							class="m-2"
						>
							<div class="float-right p-0 m-0">
								<v-ons-button
									ref="cancelBtn"
									class="bg-danger"
									@click="() => {
										redeemActionSheetVisible=false,
										selectedLoyaltyDiscount=null,
										loyalty=null
									}"
								>
									cancel
								</v-ons-button>
								<v-ons-button
									ref="apply"
									block
									:disabled="!selectedLoyaltyDiscount || !(employeePolicy.isAdmin || (
										employeePolicy.cart && employeePolicy.cart.includes('apply discount')
									))"

									@click="applyLoyaltyDiscount"
								>
									<div class="ld ld-ring ld-spin" />
									Apply
								</v-ons-button>
							</div>
						</v-ons-col>
					</v-ons-row>
				</v-ons-bottom-toolbar>
			</div>
		</v-ons-action-sheet>
	</v-ons-page>
</template>

<script>
	import Sell from '~/components/sell'
	import AddCustomer from '~/components/add-customer'
	import OrderAssignTable from '~/components/order-assign-table'
	import { ValidationObserver, ValidationProvider } from "vee-validate"


	export default {
		name: 'Checkout',
		components: {
			ValidationObserver,
			ValidationProvider
		},
		props: {
			source: {
				type: String,
				required: true
			},
			orderProp: {
				type: Object,
				default() {
					return {}
				}
			}
		},
		data() {
			return {
				order: Object.assign({}, this.orderProp),
				totalPrice: 0,
				cashAmount: null,
				denominationAmount: null,
				cardNumber: '',
				table: {},
				paymentMethods: [],
				selectedPaymentMethod: null,
				orderPayments: [],
				otherPaymentType: null,
				paymentNote: '',
				availableTables: [],
				selectedTables: [],
				tableOrders: [],
				showTableOrdersModal: false,
				processingOrder: false,
				test: false,
				print: true,
				customAttributes: {},
				splitActionSheetVisible:false,
				addDenomination: false,
				redeemActionSheetVisible: false,
				loyalty: null,
				selectedLoyaltyDiscount: null,
			}
		},
		computed: {
			pageStack: {
				get() {
					return this.$store.state.pageStack
				},
				set(pageStack) {
					this.$store.commit('setPageStack', pageStack)
				}
			},
			appVersion() {
				return this.$store.state.appVersion
			},
			appVersionNumber() {
				return parseInt(this.appVersion.replace(/\./g, ''))
			},
			deviceId() {
				return this.$store.state.deviceId
			},
			locationId() {
				return this.$store.state.locationId
			},
			merchant() {
				return this.$store.state.merchant
			},
			employee() {
				return this.$store.state.employee
			},
			checkout() {
				return this.$store.state.checkout
			},
			selectedPriceCategory() {
				return this.$store.state.selectedPriceCategory
			},
			cart() {
				return this.$store.state.cart
			},
			customers() {
				return this.$store.state.customers
			},
			cashDrawerShift() {
				return this.$store.state.cashDrawerShift
			},
			kots() {
				return this.$store.state.kots
			},
			selectedTable() {
				return this.$store.state.selectedTable
			},
			selectedOrder: {
				get() {
					return this.$store.state.selectedOrder
				},
				set(order) {
					this.$store.commit('setState', {
						key: 'selectedOrder',
						value: order
					})
				}
			},
			keyboardShortcuts() {
				return this.$store.state.keyboardShortcuts
			},
			settings() {
				return this.$store.state.settings
			},
			selectedDiscount: {
				get() {
					return this.$store.state.selectedDiscount
				},
				set(discount) {
					this.$store.commit('setState', {
						key: 'selectedDiscount',
						value: discount
					})
					this.$store.dispatch('cartCalculation')
				}
			},
			additionalInfo: {
				get() {
					return this.$store.state.additionalInfo
				},
				set(additionalInfo) {
					this.$store.commit('setState', {
							key: 'additionalInfo',
							value: additionalInfo
						})
				}
			},
			showConfirmWOPrint() {
				return this.$store.state.showConfirmWOPrint
			},
			isEzSwype() {
				return this.$store.state.isEzSwype
			},
			subscription() {
				return this.$store.state.subscription
			},
			splitPayments () {
				return this.paymentMethods.reduce((sp, pm) => {
					if (pm.slug !== 'split') {
						sp.push({
							...pm,
							amount: 0
						})
					}

					return sp
				}, [])
			},
			splitTotal: {
							get () {
								return this.orderPayments.reduce((sum, op) => {
									sum += op.amount

									return sum
								}, 0)
							},
							set () {}
						},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},

		},
		watch: {
			orderPayments (orderPayments) {
				orderPayments = orderPayments.map((op) => {
					op.amount = this.totalPrice / orderPayments.length

					return op
				})
			},
			selectedDiscount (selectedDiscount) {
				if (!selectedDiscount) {
					this.selectedLoyaltyDiscount = null
				}
			}
		},
		beforeMount() {
			this.initEventBus()
			const paymentMethods = this.$bridge.getPaymentMethods(this.deviceId, '')
			this.paymentMethods = typeof paymentMethods === 'string' ?
				JSON.parse(paymentMethods) : paymentMethods

			this.selectedPaymentMethod = this.paymentMethods[0] || null

			this.totalPrice = this.source === 'table' ?
				this.order.total_price : this.cart.price.total

			this.cashAmount = this.totalPrice.toFixed(2)

			if (this.selectedTable)
				this.selectedTables.push(this.selectedTable)

			if (this.selectedOrder && this.selectedOrder.customer)
				this.cart.customer = this.selectedOrder.customer

			if (this.order.customer && this.order.customer.id)
				this.cart.customer = this.order.customer
		},
		mounted(){
			this.test = true
			window.cardPaymentSuccess = this.cardPaymentSuccess
			window.upiPaymentSuccess = this.upiPaymentSuccess
			window.cardPaymentFailure = this.cardPaymentFailure
			setTimeout(() => {
				if (+this.settings.general.is_customer_mandatory) {
						this.addCustomer()
				}
			}, 500)
		},
		destroyed() {
			this.$store.commit('setState', {
				key: 'selectedTable',
				value: null
			})
		},
		methods: {
			initEventBus() {
				this.$root.$on('selectedTables', $event => {
					this.selectedTables = $event
				})
			},
			addCustomer() {
				this.$emit('push', {
					extends: AddCustomer
				})
			},
			resetCustomer() {
				this.$store.commit('setCustomer',{})
			},
			setPaymentMethod(paymentMethod, $event) {
				if (!$event.currentTarget.classList.contains('expanded')){
						this.selectedPaymentMethod = paymentMethod
				}
			},
			generateReceiptCode() {
				const date = new Date()
				const lastOrderDay = +this.$bridge.getLocalStorage('lastOrderDay')
				let orderCount = +this.$bridge.getLocalStorage('orderCount')

				if (lastOrderDay !== date.getDate()) {
					orderCount = 1
				} else {
					orderCount += 1
				}

				return this.$moment().format('YYMMDD') + orderCount.toString().padStart(4, '0')
			},
			preProcessOrder(print) {
				const tables = this.order.tables || this.selectedTables
				if (tables.length) {
					const tableOrders = this.$bridge.getOrders(this.deviceId, JSON.stringify({
						table_id: tables.map(table => table.id),
						status: 'pending'
					}))

					this.tableOrders = (
						typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders
					).data
					if (this.source === 'table')
						return this.validateOrder(print)
					else if (this.source === 'sell' && this.tableOrders.length) {
						if (!this.selectedOrder){
							return this.showTableOrdersModal = true
							}
						else {
							this.order = this.tableOrders
								.find(order => order.id === this.selectedOrder.id) || {}
						}
					}

					this.processOrder(false)
				} else {
					this.validateOrder(print)
				}
			},
			processTableOrder($event) {
				if (this.tableOrders[$event]) {
					this.order = { ...this.tableOrders[$event] }
					this.addOrderItemsToCart(this.order, this.cart.items.reverse())
					if (this.order.customer){
						this.cart.customer = this.order.customer
					}
				}
				this.showTableOrdersModal = false
				this.processOrder(false)
			},
			async validateOrder(print) {
				this.processingOrder = true
				switch (this.selectedPaymentMethod.slug) {
					case 'cash':
						if ((await this.$refs.cashValidator[0].validate()).valid){
							this.processOrder(print)
						} else{
							this.processingOrder = false
						}
						break
					case 'card':
						if(this.isEzSwype){
							this.print = print
							this.$bridge.payCard(JSON.stringify({"amount": this.totalPrice}))
						} else {
							if ((await this.$refs.cardValidator[0].validate()).valid){
									this.processOrder(print)
							} else{
								this.processingOrder = false
							}
						}
						break
					case 'upi':
							if(this.isEzSwype){
								this.print = print
								this.$bridge.payUPI(JSON.stringify({"amount": this.totalPrice}))
							} else {
								this.processOrder(print)
							}
							break
					case 'credit':
						this.creditValidation(print)
						break
					case 'other':
						this.processOrder(print)
						break
					case 'split':
						if ((await this.$refs.splitValidator[0].validate()).valid){
							if (this.orderPayments.findIndex(op => op.slug === 'credit') !== -1) {
								this.creditValidation(print)
							} else {
								this.processOrder(print)
							}
						} else{
							this.processingOrder = false
						}
						break
					default:
						this.processOrder(print)
						break
				}
			},
			processOrder(print) {
				const date = new Date()
				const orderId = this.order.id || date.valueOf()
				const cart = JSON.parse(JSON.stringify(this.cart))
				let items = []
				let kotItems = []
				let removedItems = []

				this.$store.commit('resetCart')

				cart.items.forEach((itemVariation, index) => {
					let removedQuantity = 0
					const totalDiscount = itemVariation.discountedAmount + itemVariation.discountedTax
					const orderItem = this.order.id ? this.filterItem(this.order.items, itemVariation) : null

					const item = {
						item_id: itemVariation.item_id,
						category_id: itemVariation.category_id,
						item_variation_id: itemVariation.id,
						item_inventory_id: itemVariation.inventory_id,
						batch_id: typeof itemVariation.batch_id === 'number' ? itemVariation.batch_id : null,
						kot_device_id: itemVariation.kot_device_id,
						item_code: orderItem ? orderItem.item_code : `${this.deviceId}${itemVariation.id}${date.valueOf()}${index}`,
						item_name: itemVariation.item_name,
						item_variation_name: itemVariation.name,
						alternate_name: itemVariation.alternate_name,
						barcode: itemVariation.barcode,
						hsn: itemVariation.hsn,
						itemization_type: itemVariation.itemization_type,
						unit_measure_type: itemVariation.unit_measure_type,
						mrp: itemVariation.mrp,
						single_quantity_amount: itemVariation.price,
						quantity: Number(itemVariation.quantity),
						groups: itemVariation.groups,
						notes: '',
						sub_total: itemVariation.subTotal,
						total: (itemVariation.subTotal + itemVariation.taxAmount) - totalDiscount,
						gross_sales: itemVariation.subTotal,
						net_sales: itemVariation.subTotal - itemVariation.discountedAmount,
						tax: itemVariation.taxAmount,
						tax_type: itemVariation.taxType,
						tax_details: itemVariation.taxes,
						discount: totalDiscount,
						discounted_amount: itemVariation.discountedAmount,
						discounted_tax: itemVariation.discountedTax,
						discount_details: itemVariation.discounts

					}

					items.unshift(item)

					if (['restaurant', 'qsr'].includes(this.merchant.businessType)) {
						const kotId = parseInt(`${orderId}${(
							this.order.id ? this.order.kot_items.length : 0
						) + kotItems.length + 1}`)

						if (!orderItem || (orderItem && item.quantity > orderItem.quantity)) {
							if (itemVariation.type === 'combo') {
								item.groups.filter(g => g.type === 'combo').forEach((g, gIndex) => {
									kotItems.push({
										id: kotId + gIndex,
										kot_id: date.valueOf(),
										kot_device_id: g.kot_device_id,
										employee_shift_id: this.employee.shiftId,
										order_id: orderId,
										item_variation_id: g.group_item_variation_id,
										item_code: `${this.deviceId}${g.group_item_variation_id}${date.valueOf()}${
											gIndex
										}`,
										item_variation_name: `${itemVariation.name} - ${g.item_variation_name}`,
										alternate_name: g.alternate_name,
										unit_measure_type: g.unit_measure_type,
										quantity: g.quantity,
										modifiers: g.modifiers,
										status: 'pending',
										status_history: [{
											status: 'pending',
											created_at: date
										}],
										created_at: date,
										updated_at: date
									})
								})
							} else {
								kotItems.push({
									id: kotId,
									kot_id: date.valueOf(),
									kot_device_id: item.kot_device_id,
									employee_shift_id: this.employee.shiftId,
									order_id: orderId,
									item_variation_id: item.item_variation_id,
									item_code: item.item_code,
									item_variation_name: item.item_variation_name,
									alternate_name: item.alternate_name,
									unit_measure_type: item.unit_measure_type,
									quantity: orderItem ? Math.abs(
										orderItem.quantity - itemVariation.quantity
									) : itemVariation.quantity,
									modifiers: item.groups.filter(g => g.type === 'modifier'),
									status: 'pending',
									status_history: [{
										status: 'pending',
										created_at: date
									}],
									created_at: date,
									updated_at: date
								})
							}
						} else if (orderItem && item.quantity < orderItem.quantity) {
							let reducedQuantity = 0
							const itemKots = this.order.kot_items.filter(i => {
								return i.item_code === item.item_code
							})

							removedQuantity = orderItem.quantity - item.quantity
							itemKots.sort((a, b) => (b.quantity - a.quantity))

							for (const i in itemKots) {
								const kotItem = itemKots[i]
								const minQuantity = Math.min(kotItem.quantity, removedQuantity)
								const quantity = kotItem.quantity - minQuantity

								if (quantity <= 0) {
									kotItem.status = 'cancelled'
									kotItem.status_history = kotItem.status_history.concat([{
										status: 'cancelled',
										created_at: date
									}])
									kotItem.updated_at = date
								} else {
									kotItem.quantity = quantity
									kotItem.updated_at = date
									reducedQuantity += minQuantity
								}

								removedQuantity -= minQuantity

								if (removedQuantity <= 0)
									break
							}

							if (reducedQuantity) {
								kotItems.push({
									id: kotId,
									kot_id: date.valueOf(),
									kot_device_id: item.kot_device_id,
									employee_shift_id: this.employee.shiftId,
									order_id: orderId,
									item_variation_id: item.item_variation_id,
									item_code: item.item_code,
									item_variation_name: item.item_variation_name,
									alternate_name: item.alternate_name,
									unit_measure_type: item.unit_measure_type,
									quantity: reducedQuantity,
									modifiers: item.groups.filter(g => g.type === 'modifier'),
									status: 'cancelled',
									status_history: [{
										status: 'cancelled',
										created_at: date
									}],
									created_at: date,
									updated_at: date
								})
							}
						}
					}
				})

				if (this.order.id) {
					removedItems = this.order.items.reduce((ri, item) => {
						if (items.findIndex(i => i.item_code === item.item_code) === -1)
							ri.push(item)

						return ri
					}, [])
				}

				for (const i in removedItems) {
					const itemKots = this.order.kot_items.filter(ki => {
						return ki.item_code === removedItems[i].item_code
					})

					for (const i in itemKots) {
						itemKots[i].status = 'cancelled'
						itemKots[i].status_history = itemKots[i].status_history.concat([{
							status: 'cancelled',
							created_at: date
						}])
						itemKots[i].updated_at = date
					}
				}

				const total = this.totalPrice
				const balanceReturned = this.selectedPaymentMethod.slug  === 'cash' ? parseFloat((this.cashAmount - total).toFixed(2)) : 0.00
				const generatedReceiptCode = this.order.receipt_code ? this.order.receipt_code
					.substr(-10) : this.generateReceiptCode()
				const receiptCode = `${this.employee.id}${this.deviceId}${date.valueOf()}${
					generatedReceiptCode
				}`
				this.customAttributes = {
					employee_shift_id: this.employee.shiftId
				}
				const labels = Object.keys(this.additionalInfo)

				if (this.selectedPaymentMethod.slug  === 'other' && this.otherPaymentType)
					this.customAttributes.other_payment_method = this.otherPaymentType

				if (labels.length) {
					this.customAttributes.labels = labels.map(i => {
						return {
							label: i,
							value: this.additionalInfo[i]
						}
					})
				}

				const tables = this.order.id && this.order.tables.length ? this.order.tables : this.selectedTables
				let tableOrders = this.$bridge.getOrders(this.deviceId, JSON.stringify({
					table_id: tables.map(t => t.id),
					status: ['pending', 'billed']
				}))

				tableOrders = (typeof tableOrders === 'string' ? JSON.parse(tableOrders) : tableOrders).data

				const creditOrderPayment = this.orderPayments.find(op => op.slug === 'credit')
				const customer = cart.customer
				const order = {
					id: orderId,
					device_id: this.deviceId,
					location_id: this.locationId,
					employee_id: this.employee.id,
					employee_shift_id: this.employee.shiftId,
					payment_method_id: this.selectedPaymentMethod ? this.selectedPaymentMethod.id : null,
					created_by: this.order.created_by ? this.order.created_by.id : null,
					employee_shift_code: this.employee.shiftCode,
					customer_id: customer.phone ? parseInt(customer.phone) : null,
					tables: tables.map(table => {
						const orders = tableOrders.filter(o => {
							return o.tables.findIndex(t => t.id === table.id) !== -1
						})

						return {
							...table,
							custom_attributes: this.selectedTables.length ?
								JSON.stringify(table.custom_attributes) : '{}',
							is_occupied: this.source === 'sell' || orders.length > 1,
							updated_at: date
						}
					}),
					merchant_price_category_id: this.order.price_category ?
						this.order.price_category.id : this.selectedPriceCategory.id,
					price_category: this.order.price_category ?
						this.order.price_category : this.selectedPriceCategory,
					status: this.selectedTables.length ? 'pending' : 'completed',
					receipt_code: receiptCode,
					sub_total: cart.price.subTotal,
					inclusive_tax: cart.price.inclusiveTaxTotal,
					additive_tax: cart.price.exclusiveTaxTotal,
					total_tax: cart.price.tax,
					taxes: cart.price.taxes,
					total_price: cart.price.total,
					total_discount: cart.price.discount,
					total_discounted_amount: cart.price.discountedAmount,
					total_discounted_tax: cart.price.discountedTax,
					discounts: this.selectedDiscount ? [this.selectedDiscount] : [],
					card_details: this.selectedPaymentMethod.slug  === 'card' && this.cardNumber ? {
						number: this.cardNumber
					} : {},
					payment_note: this.paymentNote,
					amount_balance_returned: balanceReturned,
					total_amount: this.selectedTables.length === 0 ? (
						this.selectedPaymentMethod.slug === 'cash' ? parseFloat(this.cashAmount) : total
					) : 0,
					round_off_amount: cart.price.roundOff,
					custom_attributes: this.customAttributes,
					items: items,
					order_payments: this.selectedPaymentMethod.slug !== 'split' ? [{
						payment_method_id: this.selectedPaymentMethod.id,
						employee_shift_id: this.employee.shiftId,
						order_id: orderId,
						slug: this.selectedPaymentMethod.slug,
						name: this.selectedPaymentMethod.name,
						amount: total,
						updated_at: date
					}] : this.orderPayments.map(op => ({
						payment_method_id: op.id,
						employee_shift_id: this.employee.shiftId,
						order_id: orderId,
						slug: op.slug,
						name: op.name,
						amount: op.amount,
						updated_at: date
					})),
					customers: customer.phone ? [{
						first_name: customer.first_name,
						last_name: customer.last_name,
						customer_code: customer.code,
						email: customer.email,
						phone: customer.phone,
						alternative_phone: customer.alternative_phone,
						amount: creditOrderPayment ? creditOrderPayment.amount : total,
						credit_code: receiptCode,
						credit_limit: customer.credit_limit,
						currency_code: this.merchant.currencyCode,
						calling_code: this.merchant.businessPhone.calling_code,
						address: customer.address,
						custom_attributes: customer.custom_attributes
					}] : [],
					kot_items: (this.order.id ? this.order.kot_items.concat(kotItems) : kotItems).map(i => {
						if (this.source === 'table' && this.selectedTables.length === 0 && ![
							'dispatched', 'cancelled'
						].includes(i.status)) {
							i.status = 'dispatched'
							i.status_history.push({
								status: 'dispatched',
								created_at: date
							})
						}

						return i
					}),
					created_at: this.order.created_at || this.$moment.utc(date).format('YYYY-MM-DD HH:mm:ss'),
					updated_at: date
				}

				const kotItemsToPrint = kotItems.filter(i => i.status !== 'cancelled')
				const kotPrinters = this.kots.filter(i => i.name !== 'none')

				if (kotPrinters.length && kotItemsToPrint.length && ['restaurant', 'qsr'].includes(this.merchant.businessType) && this.source === 'sell') {
					window.printKot({
						...order,
						items: this.groupBy(kotItemsToPrint, 'kot_device_id'),
						is_running_order: this.order.id ? true : false
					})
				}

				if (print) {
					this.$store.commit('setState', {
						key: 'orderReceipt',
						value: {
							print: true,
							type: 'order',
							data: Object.assign({}, order)
						}
					})
				}
				this.$bridge.put('Order', this.$bridge.getName() === 'ANDROID' ?
					JSON.stringify(order) : order)

				if (customer.phone) {

					const orderCustomer = Object.assign({}, customer, {
						device_id: this.deviceId,
						address: JSON.stringify(customer.address),
						credit: this.selectedPaymentMethod.slug === 'credit' || creditOrderPayment ? (
							(customer.credit || 0) + (creditOrderPayment ? creditOrderPayment.amount : total)
						) : (customer.credit || 0),

						custom_attributes: JSON.stringify(customer.custom_attributes),
						updated_at: date
					})

					this.$bridge.put('Customer', this.$bridge.getName() === 'ANDROID' ?
						JSON.stringify(orderCustomer) : orderCustomer)
				}

				if (!this.order.id) {
					this.$bridge.put('lastOrderDay', date.getDate())
					this.$bridge.put('orderCount', generatedReceiptCode.substr(-4))
				}

				if (order.status === 'completed') {
					order.tables = order.tables.map(table => table.id)
					delete order.updated_at

					let syncData = {
						id: date.valueOf(),
						model_id: order.id,
						model_name: 'order',
						payload: JSON.stringify({
							model_id: order.id,
							device_id: order.device_id,
							location_id: order.location_id,
							employee_id: order.employee_id,
							orders: [order]
						})
					}
					this.$bridge.put('OrderSync', this.$bridge.getName() === 'ANDROID' ? JSON.stringify(syncData) : syncData)

					const cashOrderPayment = this.orderPayments.find(op => op.slug === 'cash')
					if (this.selectedPaymentMethod.slug === 'cash' || cashOrderPayment) {
						const event = {
							id: date.valueOf(),
							device_id: this.deviceId,
							employee_id: this.employee.id,
							employee_shift_id: this.employee.shiftId,
							cash_drawer_shift_id: this.cashDrawerShift.id,
							amount: cashOrderPayment ? cashOrderPayment.amount : total,
							cash_via: 'sale',
							type: 'cash_in',
							updated_at: date
						}

						this.$bridge.put('CashDrawerShiftEvent', this.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(event) : event)

						syncData = {
							id: date.valueOf() + 1,
							model_id: event.id,
							model_name: 'cash-drawer-shift-event',
							payload: JSON.stringify({
								model_id: event.id,
								cash_drawer_shift_id: event.cash_drawer_shift_id,
								employee_id: event.employee_id,
								event_type: event.type,
								cash_via: event.cash_via,
								event_money: event.amount,
								shift_event_code: `${this.deviceId}${date.valueOf()}`,
								employee_shift_code: this.employee.shiftCode
							})
						}

						this.$bridge.put('CashDrawerShiftEventSync', this.$bridge.getName() === 'ANDROID' ?
							JSON.stringify(syncData) : syncData)
					}
				}

				this.selectedDiscount = null
				this.additionalInfo = {}
				this.pageStack = [this.pageStack[0]]
				if(this.source === 'table'){
					this.$root.$emit('updateTableOrder', true)
				}
				this.$store.commit('setSelectedVariation', null)
				this.$ons.notification.alert('Order placed successfully!', {
					title: 'Success'
				})
			},
			assignTable(){
				this.$emit('push', {
					extends: OrderAssignTable,
					onsNavigatorProps: {
						tablesSelected: this.selectedTables
					}
				})
			},
			resetSelectedTable(){
				this.selectedTables = []
			},
			resetSelectedTableItem(index){
				this.selectedTables.splice(index, 1)
			},
			cardPaymentSuccess(data){
				this.customAttributes = data
				this.processOrder(this.print)
			},
			upiPaymentSuccess(){
				this.processOrder(this.print)
			},
			cardPaymentFailure(message){
				this.$ons.notification.toast(message, {timeout: 2000})
			},
			calculateSplitAmount (index,value) {
				this.orderPayments[index].amount = Number(value)
				if (this.orderPayments.length === 2) {
					this.orderPayments[index === 0 ? 1 : 0].amount = this.totalPrice - this.orderPayments[index].amount
				}

			},
			selectedSplitPayment(checked,value){
				if (checked) {
					this.orderPayments.push(value)
				}else {
					var index = this.orderPayments.findIndex(pm=> pm.id === value.id)
					this.orderPayments.splice(index,1)
				}
			},
			creditValidation (print) {
				if (this.cart.customer.phone) {
					if (((this.cart.customer.credit && !this.cart.customer.debit ?
						this.cart.customer.credit : (this.cart.customer.credit - this.cart.customer.debit)
					) + this.totalPrice) > this.cart.customer.credit_limit) {
						this.$ons.notification.confirm('Are you sure you want to continue?', {
							title: 'Credit limt exceeded!'
						}).then(response => {
							if (response)
								this.processOrder(print)
							else
								this.processingOrder = false
						})
					} else {
						this.processOrder(print)
					}
				} else {
					this.$ons.notification.alert('Please add customer to continue', {
						title: 'Customer not added'
					})

					this.processingOrder = false
				}
			},
			getLoyaltyPoints () {
				this.redeemActionSheetVisible = true
				let total = this.cart.price.total

				if (this.selectedDiscount) {
					total += this.cart.items.reduce((discountSum, i) => {
						const index = i.discounts.findIndex(d => d.id === this.selectedDiscount.id)

						if (index !== -1) {
							discountSum += i.discounts[index].discounted_amount + i.discounts[index].discounted_tax
						}

						return discountSum
					}, 0)
				}

				this.$store.dispatch('getLoyaltyPoints', {
					merchant_customer_id: this.cart.customer.customer_id,
					location_id: this.locationId,
					total_price: total
				}).then((response) => {
					this.loyalty = response.data
				}).catch((err) => {
					console.error(err) // eslint-disable-line
				})
			},
			applyLoyaltyDiscount () {
				this.selectedDiscount = {
					id: this.selectedLoyaltyDiscount.id,
					name: this.selectedLoyaltyDiscount.name,
					description: this.selectedLoyaltyDiscount.description,
					get_discount_type: this.selectedLoyaltyDiscount.getDiscountType,
					get_discount_value: this.selectedLoyaltyDiscount.getDiscountValue,
					apply_discount_sub_total: this.selectedLoyaltyDiscount.applyDiscountSubTotal,
					is_automatic: this.selectedLoyaltyDiscount.isAutomatic
				}

				this.$store.dispatch('cartCalculation')
				this.redeemActionSheetVisible = false
				this.loyalty=null
			},

		}
	}
</script>
