var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-ons-page',{attrs:{"modifier":"cash-drawer-page"}},[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Cash Drawer")]),_vm._v(" "),_c('v-ons-toolbar',[(_vm.cashDrawer.close)?_c('div',{staticClass:"left"},[_c('v-ons-back-button')],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"center"},[_vm._v("\n\t\t\tCash Drawer\n\t\t")])]),_vm._v(" "),_c('div',{staticStyle:{"padding":"10px"}},[_c('ValidationObserver',{ref:"validator"},[_c('ValidationProvider',{attrs:{"name":"amount","rules":{
					required: true,
					decimal: 2,
					min_value: ['cash in', 'cash out'].includes(_vm.cashDrawer.type) ? .01 : 0
				}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
				var errors = ref.errors;
return [_c('v-ons-input',{staticClass:"w-100",class:{
						'mb-3': _vm.cashDrawer.type !== 'closing'
					},attrs:{"type":"number","step":".01","name":"amount","placeholder":("Enter " + (_vm.cashDrawer.type) + " amount"),"float":""},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v(" "),(_vm.cashDrawer.type !== 'closing')?_c('div',[_c('v-ons-input',{staticClass:"w-100",class:{
					'mb-3': _vm.cashDrawer.type === 'cash out'
				},attrs:{"type":"text","name":"description","placeholder":"Description","float":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._v(" "),(_vm.cashDrawer.type === 'cash out')?_c('v-ons-select',{staticClass:"w-100",model:{value:(_vm.cashVia),callback:function ($$v) {_vm.cashVia=$$v},expression:"cashVia"}},_vm._l((_vm.paidForOptions),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n\t\t\t\t\t"+_vm._s(option.text)+"\n\t\t\t\t")])}),0):_vm._e()],1):_vm._e(),_vm._v(" "),((_vm.$store.state.settings.denominations.length>1))?_c('a',{staticStyle:{"margin-top":"10px","float":"right"},attrs:{"href":"#"},on:{"click":function () {
				_vm.addDenomination = true
			}}},[_vm._v("\n\t\t\tAdd denomination\n\t\t")]):_vm._e(),_vm._v(" "),_c('v-ons-action-sheet',{attrs:{"title":"Denominations","modifier":"denominations-action-sheet-container","cancelable":"","visible":_vm.addDenomination},on:{"update:visible":function($event){_vm.addDenomination=$event},"posthide":function () {
				_vm.amount = _vm.denominationAmount !== null ? _vm.denominationAmount : _vm.amount
				_vm.addDenomination = false
			}}},[_c('div',[_c('denominations',{on:{"update:amount":function($event){_vm.denominationAmount = $event}}})],1)]),_vm._v(" "),_c('v-ons-bottom-toolbar',{staticStyle:{"height":"52px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('v-ons-col',{staticStyle:{"padding":"10px"},attrs:{"vertical-align":"center"}},[(_vm.cashDrawer.close)?_c('v-ons-button',{staticStyle:{"margin-right":"10px"},attrs:{"modifier":"large"},on:{"click":_vm.cancel}},[_vm._v("\n\t\t\t\t\t\tCancel\n\t\t\t\t\t")]):_vm._e()],1),_vm._v(" "),_c('v-ons-col',{staticStyle:{"padding":"10px"},attrs:{"vertical-align":"center"}},[_c('v-ons-button',{attrs:{"modifier":"large"},on:{"click":_vm.processShift}},[_vm._v("\n\t\t\t\t\t\tSubmit\n\t\t\t\t\t")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }